import React, { Component } from "react";

import MenuItem from "./MenuItem";

class MenuEngineSystem extends Component {
    state = {
        display: this.props.display,
    };

    handleOnClick = (head, component, content, contentTitle) => {
        this.props.onClick(head, component, content, contentTitle);
    };

    static getDerivedStateFromProps(props, state) {
        if (props.display !== state.display) {
            return {
                display: props.display
            };
        }
        return null
    }

    render() {
        let classStr;

        if (!this.state.display) {
            classStr = 'menus';
        } else {
            classStr = 'menus active';
        }

        const low_maintenance_content = '<p>Perkins engines are low maintenance.</p>'
            + '<p>Perkins engines have been designed to reduce rental company operating costs and support efficient '
            + 'maintenance. With the latest in electronic sensors and software, rental companies and Perkins '
            + 'distributors can quickly diagnose issues and get the machine back to work.</p>';

        return (
            <ul className={classStr} style={this.props.menuStyle}>
                <span style={{ left: "40%", marginTop: "25px"  }}></span>
                <MenuItem component="engine-system" contentTitle="Engine system" title="Low maintenance" content={low_maintenance_content} onClick={this.handleOnClick} head="tad-petrie-strategy" />
            </ul>
        );
    }
}

export default MenuEngineSystem;
