import React, { Component } from "react";
import "../components/VideoModal.css";

class VideoModal extends Component {
    state = {
        playing: true,
        hideClass: 'hide'
    };

    handleClickOutside = e => {
        if (e.target.id === "video-modal") {
            this.handleClose();
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.modalVideo !== prevState.modalVideo) {
            return { modalVideo: nextProps.modalVideo, hideClass: 'hide' };
        }

        return null;
    }

    handleClose = () => {
        this.props.onClick();
        this.refs.video.pause();
    };

    playMe = (e) => {
        e.target.play();

        this.setState({
            hideClass: ''
        });
    }

    render() {
        const { modalVideo } = this.state;
        let videoSrc;

        if (modalVideo !== "" && modalVideo !== undefined && modalVideo !== null) {
			videoSrc = require("../assets/video/talking-heads/" + modalVideo + ".mp4");
        } else {
			videoSrc = null;
        }

        return (
            <div className={this.state.hideClass} id="video-modal" onClick={this.handleClickOutside} style={this.props.compStyle}>
                <button className="close" onClick={this.handleClose} />
                <video ref="video" className="head" controls src={videoSrc} onCanPlayThrough={this.playMe} controlsList="nodownload nofullscreen" />
            </div>
        );
    }
}

export default VideoModal;
