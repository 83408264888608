import React, { Component } from "react";

class Loader extends Component {
    render() {
        return (
            <div className="spinner">
                <div>
                    <img src={require(".././assets/spinner.svg")} alt="Spinner" />
                    <h2>Preloading assets...</h2>
                </div>
            </div>
        );
    }
}
export default Loader;
