import React, { Component } from "react";

import "../components/ComponentModal.css";

class ComponentModal extends Component {
    state = {
        component: this.props.component,
        playing: true,
        hideClass: 'hide'
    };

    handleClickOutside = e => {
        if (e.target.id === "component-modal") {
            this.handleClose();
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.component !== prevState.component) {
            return { component: nextProps.component, head: nextProps.head, hideClass: 'hide' };
        }

        return null;
    }

    handleClose = () => {
        this.props.onClick();
        this.refs.headVid.pause();
        this.refs.compVid.pause();
    };

    playMe = (e) => {
        e.target.play();

        this.setState({
            hideClass: ''
        });
    }

    render() {
        const { component, head } = this.state;
        let headSrc, componentSrc;

        if (component !== "" && component !== undefined && component !== null) {
            headSrc = require("../assets/video/talking-heads/" + head + ".mp4");
            componentSrc = require("../assets/video/" + component + ".mp4");
        } else {
            headSrc = null;
            componentSrc = null;
        }

        return (
            <div className={this.state.hideClass} id="component-modal" onClick={this.handleClickOutside} style={this.props.compStyle}>
                <button className="close" onClick={this.handleClose} />
                <video ref="headVid" className="head" controls src={headSrc} onCanPlayThrough={this.playMe} controlsList="nodownload nofullscreen" />
                <video ref="compVid" className="component" muted loop src={componentSrc} onCanPlayThrough={this.playMe} controlsList="nodownload nofullscreen" />
            </div>
        );
    }
}

export default ComponentModal;
