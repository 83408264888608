import React, { Component } from "react";

import MenuItem from "./MenuItem";

class MenuIntake extends Component {
    state = {
        display: this.props.display,
    };

    handleOnClick = (head, component, content, contentTitle) => {
        this.props.onClick(head, component, content, contentTitle);
    };

    static getDerivedStateFromProps(props, state) {
        if (props.display !== state.display) {
            return {
                display: props.display
            };
        }
        return null
    }

    render() {
        let classStr;

        if (!this.state.display) {
            classStr = 'menus';
        } else {
            classStr = 'menus active';
        }

        const simple_operation_content = '<p>Perkins engines are simple to operate.</p>'
        + '<p>Perkins engines have been designed to keep operators happy. With simple but effective temperature '
        + 'control, such as this intake throttle valve, we can ensure that our DPFs (diesel particulate filters) '
        + 'regenerate without interrupting the operator.</p>';

        return (
            <ul className={classStr} style={this.props.menuStyle}>
                <span style={{ left: "40%", marginTop: "25px"  }}></span>
                <MenuItem component="intake" contentTitle="Intake throttle valve" title="Simple to operate" content={simple_operation_content} onClick={this.handleOnClick} head="bertrand-itv" />
            </ul>
        );
    }
}

export default MenuIntake;
