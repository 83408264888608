import React, { Component } from "react";

import MenuItem from "./MenuItem";

class MenuEGR extends Component {
    state = {
        display: this.props.display,
    };

    handleOnClick = (head, component, content, contentTitle) => {
        this.props.onClick(head, component, content, contentTitle);
    };

    static getDerivedStateFromProps(props, state) {
        if (props.display !== state.display) {
            return {
                display: props.display
            };
        }
        return null
    }

    render() {
        let classStr;

        if (!this.state.display) {
            classStr = 'menus';
        } else {
            classStr = 'menus active';
        }

        const maximum_uptime_content = '<p>Perkins engines deliver more uptime.</p>'
        + '<p>Perkins engines have been designed to deliver reliability in cold temperatures. With EGR (exhaust gas '
        + 'recirculation) Perkins can reduce the amount of DEF (diesel emissions fluid) required and reduce the '
        + 'likelihood of blockages in cold weather.</p>';

        const simple_operation_content = '<p>Perkins engines are simple to operate.</p>'
        + '<p>Perkins engines have been designed to limit the amount of DEF (diesel emissions fluid) required by '
        + 'operators. With EGR (exhaust gas recirculation) technology, we limit the amount of NOx (nitrous oxide) '
        + 'produced by the engine, reducing the volume of DEF required by the operator.</p>';

        return (
            <ul className={classStr} style={this.props.menuStyle}>
                <span style={{ left: "35%", marginTop: "54px"  }}></span>
                <MenuItem component="egr" contentTitle="Exhaust gas recirculation valve" title="Maximum uptime" content={maximum_uptime_content} onClick={this.handleOnClick} head="tad-petrie-durability"  />
                <MenuItem component="egr" contentTitle="Exhaust gas recirculation valve" title="Simple to operate" content={simple_operation_content} onClick={this.handleOnClick} head="bertrand-egr"  />
            </ul>
        );
    }
}

export default MenuEGR;
