import React, { Component } from "react";
import "./App.css";
import "../node_modules/uikit/dist/css/uikit.min.css";
import "../node_modules/uikit/dist/js/uikit.min.js";
import Preload from "react-preload";
import IdleTimer from "react-idle-timer";

import Rotator from "./components/Rotator";
import ComponentModal from "./components/ComponentModal";
import VideoModal from "./components/VideoModal";

import Hotspot from "./components/Hotspot";
import Loader from "./components/Loader";

import MenuAftertreatment from "./components/MenuAftertreatment";
import MenuEGR from "./components/MenuEGR";
import MenuEngineSystem from "./components/MenuEngineSystem";
import MenuFuelInjection from "./components/MenuFuelInjection";
import MenuIntake from "./components/MenuIntake";
import MenuTurbo from "./components/MenuTurbo";

class App extends Component {
    state = {
        selected: "",
        src: "",
        reverseSrc: "",
        clickDisabler: "click-disabler",
        component: null,
        head: null,
        content: null,
        contentTitle: null,
        display: "uk-hidden",
        nextTech: "",
        reverseDisplay: "uk-hidden",
        compAftertreatment: false,
        compBracket: false,
        compEGR: false,
        compFuelInjection: false,
        compEngineSystem: false,
        compIntake: false,
        compTurbo: false,
        menuAftertreatment: false,
        menuEGR: false,
        menuFuelInjection: false,
        menuEngineSystem: false,
        menuIntake: false,
        menuTurbo: false,
        firstLoad: true,
        vOffset: { bottom: "0px" },
        hvOffset: { bottom: "0px", top: "0px" },
        idleClassName: 'rotate-icon',
        modalVideo: null,
    };

    constructor(props) {
        super(props);
        this.componentModal = React.createRef();
        this.videoModal = React.createRef();
        this.hotspots = React.createRef();
        this.transitionVideo = React.createRef();
        this.transitionVideoReverse = React.createRef();

        window.addEventListener("resize", this.updateDimensions);

        // this.updateDimensions();
    }

    componentDidMount() {
        if (this.state.firstLoad === true) {
            this.updateDimensions();
        }
    }

    updateDimensions = () => {
        var offset = Math.round(window.innerHeight - window.innerWidth * 0.5625);
        if (offset < 0) offset = 0;

        this.setState({
            vOffset: { bottom: offset + "px" },
            hvOffset: { bottom: offset / 2 + "px", top: offset / 2 + "px" },
            firstLoad: false
        });
    };

    getFrames = () => {
        let images = [];
        for (let i = 0; i < 121; i = i + 1) {
            let filename = "00";
            if (i < 10) {
                filename = "0000";
            } else if (i < 100) {
                filename = "000";
            }

            images.push(require("./assets/images/frame-" + filename + i + ".png"));
        }

        return images;
    };

    handleRotationChange = frame => {
        // aftertreatment
        if (frame > 30 && frame < 45) {
            if (this.state.compAftertreatment === false) {
                this.setState({
                    compAftertreatment: true
                });
            }
        } else {
            if (this.state.compAftertreatment === true) {
                this.setState({
                    compAftertreatment: false,
                    menuAftertreatment: false
                });
            }
        }

        // // bracket
        // if (frame > 48 && frame < 58) {
        //     if (this.state.compBracket === false) {
        //         this.setState({
        //             compBracket: true
        //         });
        //     }
        // } else {
        //     if (this.state.compBracket === true) {
        //         this.setState({
        //             compBracket: false,
        //             menuBracket: false
        //         });
        //     }
        // }

        // egr
        if (frame > 97 && frame < 106) {
            if (this.state.compEGR === false) {
                this.setState({
                    compEGR: true
                });
            }
        } else {
            if (this.state.compEGR === true) {
                this.setState({
                    compEGR: false,
                    menuEGR: false
                });
            }
        }

        // engine
        if (frame > 1 && frame < 10) {
            if (this.state.compEngineSystem === false) {
                this.setState({
                    compEngineSystem: true
                });
            }
        } else {
            if (this.state.compEngineSystem === true) {
                this.setState({
                    compEngineSystem: false,
                    menuEngineSystem: false
                });
            }
        }

        // intake
        if (frame > 79 && frame < 91) {
            if (this.state.compIntake === false) {
                this.setState({
                    compIntake: true
                });
            }
        } else {
            if (this.state.compIntake === true) {
                this.setState({
                    compIntake: false,
                    menuIntake: false
                });
            }
        }

        // fuel injection
        if (frame > 64 && frame < 76) {
            if (this.state.compFuelInjection === false) {
                this.setState({
                    compFuelInjection: true
                });
            }
        } else {
            if (this.state.compFuelInjection === true) {
                this.setState({
                    compFuelInjection: false,
                    menuFuelInjection: false
                });
            }
        }

        // turbo
        if (frame > 13 && frame < 25) {
            if (this.state.compTurbo === false) {
                this.setState({
                    compTurbo: true
                });
            }
        } else {
            if (this.state.compTurbo === true) {
                this.setState({
                    compTurbo: false,
                    menuTurbo: false
                });
            }
        }
    };

    showComponent = component => {
        this.setState({
            component: component
        });
    };

    hideComponent = () => {
        this.setState({
            component: "",
            head: "",
            content: "",
            contentTitle: "",
            clickDisabler: 'click-disabler'
        });
    };

    hideVideo = () => {
        this.setState({
            modalVideo: "",
            clickDisabler: 'click-disabler'
        });
    };

    handleHotspotClick = component => {
        this.setState({
            menuAftertreatment: false,
            menuEGR: false,
            menuFuelInjection: false,
            menuEngineSystem: false,
            menuIntake: false,
            menuTurbo: false
        });

        this.setState({
            [component]: true
        });
    };

    openPlayingHead = (head, component, content, contentTitle) => {
        this.setState({
            component: component,
            head: head,
            content: content,
            contentTitle: contentTitle,
            clickDisabler: 'click-disabler active'
        });
    };

    onIdle = () => {
        if(this.state.idleClassName === 'rotate-icon'){
            this.setState({
                idleClassName: 'rotate-icon fade'
            })
        }
    }

    onIdleDone = () => {
        if (this.state.idleClassName === 'rotate-icon fade') {
            this.setState({
                idleClassName: 'rotate-icon'
            })
        }
    }

    showVideoComponent = (video) => {
        this.setState({
            modalVideo: video,
            clickDisabler: 'click-disabler active'
        });
    }

    render() {
        // <HotspotContainer ref={this.hotspots} modal={this.componentModal} tech={this.state.hotspotTech} onClick={this.showComponent} />
        const images = this.getFrames();
        const idleEvents = [
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mouseWheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove'
        ];
        return (
            <div>
            <div className="orient_overlay">
                <div>
                    <img src={require("./assets/turn-device.svg")} draggable="false" alt="Rotate device" />
                    <h3 className="uk-margin-medium-bottom">Please rotate your device</h3>
                    <p>
                        <img src={require("./assets/perkins-logo-blue.svg")}  draggable="false" alt="Perkins Logo" />
                    </p>
                </div>
            </div>
            <div className="too_small_overlay">
                <div>
                    <h3>
                        This experience is designed for screens
                        at least 1024x768 pixels in size.
                    </h3>
                    <p className="uk-margin-medium-bottom">
                        Please try running this in full screen or on a larger device.
                    </p>
                    <p>
                        <img src={require("./assets/perkins-logo-blue.svg")}  draggable="false" alt="Perkins Logo" />
                    </p>
                </div>
            </div>

            <div className="no-portrait">
                <h2>There was a problem with your browser.</h2>
            </div>
            <Preload images={images} loadingIndicator={<Loader />} autoResolveDelay={50000}>
                <IdleTimer
                    onIdle={this.onIdle}
                    timeout={5000}
                    onActive={this.onIdleDone}
                    events={idleEvents}
                />
                <div className="main uk-container uk-container-large uk-padding-remove">
                    <div className="wrap">
                        <img className={this.state.idleClassName} src={require('./assets/arrows.svg')} alt="Rotate"/>
                        <img className="curve" src={require("./assets/heart-curve.png")} alt="Perkins curve" />
                        <img onClick={() => this.showVideoComponent('bertrand-rental')} className="logo" src={require("./assets/perkins-logo.svg")} alt="Perkins Logo" />

                        <div className="title-copy">
                            <h1 onClick={() => this.showVideoComponent('ellie-rental')}>Made for rental</h1>
                            <p>Delivering maximum return on investment for even the most demanding of rental companies</p>
                        </div>

                        <div className="footer-copy">
                            <h2 onClick={() => this.showVideoComponent('tad-petrie-rental-focus')}>
                                Rental Focused, Rental Strong
                            </h2>
                        </div>

                        <div id="click-disabler" className={this.state.clickDisabler} />

                        <div id="inRatioWrapper" style={this.state.hvOffset}>
                            <Rotator reverse={true} onRef={ref => (this.rotator = ref)} className="uk-position-z-index rotator uk-position-cover" cycle={true} onChange={this.handleRotationChange}>
                                {images.map((url, i) => (
                                    <div className="rotator-frame" style={{ backgroundImage: "url(" + url + ")" }} key={i} />
                                ))}
                            </Rotator>

                            <div id="hotspots">
                                <Hotspot display={this.state.compAftertreatment} onClick={this.handleHotspotClick} component="menuAftertreatment" hotspotStyle={{ left: "50%", top: "26%" }} />
                                <Hotspot display={this.state.compEGR} onClick={this.handleHotspotClick} component="menuEGR" hotspotStyle={{ left: "35%", top: "20%" }} />
                                <Hotspot display={this.state.compEngineSystem} onClick={this.handleHotspotClick} component="menuEngineSystem" hotspotStyle={{ left: "40%", top: "32%" }} />
                                <Hotspot display={this.state.compFuelInjection} onClick={this.handleHotspotClick} component="menuFuelInjection" hotspotStyle={{ left: "44%", top: "19%" }} />
                                <Hotspot display={this.state.compIntake} onClick={this.handleHotspotClick} component="menuIntake" hotspotStyle={{ left: "40%", top: "28%" }} />
                                <Hotspot display={this.state.compTurbo} onClick={this.handleHotspotClick} component="menuTurbo" hotspotStyle={{ left: "39%", top: "19%" }} />
                            </div>

                            <ComponentModal ref={this.componentModal} component={this.state.component} head={this.state.head} content={this.state.content} onClick={this.hideComponent} />
                            <VideoModal ref={this.videoModal} modalVideo={this.state.modalVideo} onClick={this.hideVideo} />


                            <div className="content-wrap">
                                <div className="component-text">
                                    <h3>{this.state.contentTitle}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
                                </div>

                                <div className="link-wrapper">
                                    <p>
                                        <a href="http://distributorlocator.perkins.com/" target="blank">
                                            Contact Your Local Distributor
                                        </a>
                                        <img src={require("./assets/icon-place.svg")} alt="Place" />
                                    </p>
                                    <p>
                                        <a href="https://www.youtube.com/channel/UCzdvKbH9f29PWl4WUwsUuWA" target="blank">
                                            Perkins YouTube Channel
                                        </a>
                                        <img src={require("./assets/icon-video.svg")} alt="Place" />
                                    </p>
                                    <p>
                                        <a href="https://www.perkins.com/" target="blank">
                                            www.perkins.com
                                        </a>
                                        <img src={require("./assets/icon-globe.svg")} alt="Place" />
                                    </p>
                                </div>

                                <MenuAftertreatment
                                    display={this.state.menuAftertreatment}
                                    menuStyle={{ left: "50%", top: "26%", marginLeft: "24%", marginTop: "10px" }}
                                    onClick={this.openPlayingHead}
                                />
                                <MenuEGR display={this.state.menuEGR} menuStyle={{ left: "35%", top: "20%", marginLeft: "39%" }} onClick={this.openPlayingHead} />
                                <MenuEngineSystem display={this.state.menuEngineSystem} menuStyle={{ left: "40%", top: "32%", marginLeft: "33%" }} onClick={this.openPlayingHead} />
                                <MenuFuelInjection
                                    display={this.state.menuFuelInjection}
                                    menuStyle={{ left: "44%", top: "19%", marginLeft: "30%", marginTop: "40px" }}
                                    onClick={this.openPlayingHead}
                                />
                                <MenuIntake display={this.state.menuIntake} menuStyle={{ left: "40%", top: "28%", marginLeft: "34%" }} onClick={this.openPlayingHead} />
                                <MenuTurbo display={this.state.menuTurbo} menuStyle={{ left: "39%", top: "19%", marginLeft: "35%", marginTop: "40px" }} onClick={this.openPlayingHead} />
                            </div>
                        </div>
                    </div>
                </div>
            </Preload>
            </div>
        );
    }
}

export default App;
