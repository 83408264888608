import React, { Component } from "react";

class Hotspot extends Component {
    state = {
        // src: "",
        display: this.props.display,
        tech: ""
    };

    handleClick = () => {
        this.props.onClick(this.props.component);
    };

    static getDerivedStateFromProps(props, state) {
        // console.log(props);
        // console.log(state);
        if (props.display !== state.display) {
            return {
                display: props.display
            };
        }
        return null
    }

    render() {
        let classStr;

        if (!this.state.display) {
            classStr = 'spot';
        } else {
            classStr = 'spot active';
        }

        return (
            <div className={classStr} onClick={this.handleClick} component={this.props.component} style={this.props.hotspotStyle}>
                <div className="dot" />
                <div className="pulse" />
                <div className="stroke" />
            </div>
        );
    }
}

export default Hotspot;
