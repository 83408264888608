import React, { Component } from "react";

class MenuItem extends Component {
    state = {
        status: "closed"
    };

    handleOnClick = () => {
		this.props.onClick(this.props.head, this.props.component, this.props.content, this.props.contentTitle);
    };

    render() {
        return (
            <li onClick={this.handleOnClick}>
                {this.props.title}
            </li>
        );
    }
}

export default MenuItem;
