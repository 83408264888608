import React, { Component } from "react";

import MenuItem from "./MenuItem";

class MenuAftertreatment extends Component {
    state = {
        display: this.props.display,
    };

    handleOnClick = (head, component, content, contentTitle) => {
        this.props.onClick(head, component, content, contentTitle);
    };

    static getDerivedStateFromProps(props, state) {
        if (props.display !== state.display) {
            return {
                display: props.display
            };
        }
        return null
    }

    render() {
        let classStr;

        if (!this.state.display) {
            classStr = 'menus';
        } else {
            classStr = 'menus active';
        }

        const maximum_uptime_content = '<p>Perkins engines deliver more uptime.</p>'
        + '<p>Perkins engines have been designed to meet the needs of many applications, from high power excavators to'
        + ' low duty cycle lift platforms. With the latest in catalyst technology, we can ensure regeneration occurs'
        + ' seamlessly, providing maximum uptime.</p>';

        const high_resale_value_content = '<p>Perkins engines protect machine re-sale value.</p>'
        + '<p>Perkins engines have been designed to deliver reliability for many thousands of hours. With the latest'
        + ' in aftertreatment technology, Perkins can keep servicing requirements to a minimum and protect re-sale'
        + ' value.</p>';

        const low_maintenance_content = '<p>Perkins engines are low maintenance.</p>'
        + '<p>Perkins engines have been designed to reduce rental company operating costs and remove the requirement'
        + ' to service DPFs (diesel particulate filters). With sophisticated sensors and control strategies, we can'
        + ' monitor and adjust the performance of DPF and SCR (selective catalytic reduction) and keep maintenance'
        + ' to a minimum.</p>';

        return (

            <ul className={classStr} style={this.props.menuStyle}>
                <span style={{ left: "50%", marginTop: "85px"  }}></span>
                <MenuItem component="aftertreatment" contentTitle="Aftertreatment" title="Maximum uptime" content={maximum_uptime_content} onClick={this.handleOnClick} head="ellie-dpf" />
                <MenuItem component="aftertreatment" contentTitle="Aftertreatment" title="High re-sale value" content={high_resale_value_content} onClick={this.handleOnClick} head="tad-petrie-resale-value" />
                <MenuItem component="aftertreatment" contentTitle="Aftertreatment" title="Low maintenance" content={low_maintenance_content} onClick={this.handleOnClick} head="ellie-systems" />
            </ul>
        );
    }
}

export default MenuAftertreatment;
