import React, { Component } from "react";

import MenuItem from "./MenuItem";

class MenuTurbo extends Component {
    state = {
        display: this.props.display,
    };

    handleOnClick = (head, component, content, contentTitle) => {
        this.props.onClick(head, component, content, contentTitle);
    };

    static getDerivedStateFromProps(props, state) {
        if (props.display !== state.display) {
            return {
                display: props.display
            };
        }
        return null
    }

    render() {
        let classStr;

        if (!this.state.display) {
            classStr = 'menus';
        } else {
            classStr = 'menus active';
        }

        const maximum_uptime_content = '<p>Perkins engines deliver more uptime.</p>'
        + '<p>Perkins engines have been designed to keep operators happy. With simple but effective temperature '
        + 'control, such as this electronically wastegated turbo, we can ensure that our DPFs (diesel particulate '
        + 'filters) regenerate without interrupting the operator.</p>';

        const simple_operation_content = '<p>Perkins engines are simple to operate.</p>'
        + '<p>Perkins engines have been designed to be as compact as possible without compromising on power or '
        + 'performance. With the latest in turbocharging technology, Perkins can help machine manufacturers to '
        + 'downsize; delivering a smaller, more cost effective machine, which can be used on even the most constrained '
        + 'worksites.</p>';

        return (
            <ul className={classStr} style={this.props.menuStyle}>
                <span style={{ left: "39%", marginTop: "54px"  }}></span>
                <MenuItem component="turbo" contentTitle="Turbocharger" title="Maximum uptime" content={maximum_uptime_content} onClick={this.handleOnClick} head="ellie-turbo" />
                <MenuItem component="turbo" contentTitle="Turbocharger" title="Simple to operate" content={simple_operation_content} onClick={this.handleOnClick} head="bertrand-turbo" />
            </ul>
        );
    }
}

export default MenuTurbo;
