import React, { Component } from "react";

import MenuItem from "./MenuItem";

class MenuFuelInjection extends Component {
    state = {
        display: this.props.display,
    };

    handleOnClick = (head, component, content, contentTitle) => {
        this.props.onClick(head, component, content, contentTitle);
    };

    static getDerivedStateFromProps(props, state) {
        if (props.display !== state.display) {
            return {
                display: props.display
            };
        }
        return null
    }

    render() {
        let classStr;

        if (!this.state.display) {
            classStr = 'menus';
        } else {
            classStr = 'menus active';
        }

        const maximum_uptime_content = '<p>Perkins engines deliver more uptime.</p>'
        + '<p>Perkins engines have been designed to keep operators and neighbours happy. With the latest in noise '
        + 'reducing fuel injection technology, Perkins engines help to keep machine noise to a minimum and maximise '
        + 'running hours.</p>';

        const low_maintenance_content = '<p>Perkins engines are low maintenance.</p>'
        + '<p>Perkins engines have been designed to reduce rental company operating costs and remove the requirement '
        + 'to service DPFs (diesel particulate filters). With the latest in fuel system technology, we can reduce the '
        + 'amount of particulate matter created during combustion and keep maintenance to a minimum.</p>';

        const high_resale_value_content = '<p>Perkins engines protect machine re-sale value.</p>'
        + '<p>Perkins engines have been designed to deliver reliability for many thousands of hours. With the latest in '
        + 'fuel injectors, Perkins engines are more robust to poor quality fuels and help to protect machine re-sale '
        + 'value.</p>';

        return (
            <ul className={classStr} style={this.props.menuStyle}>
                <span style={{ left: "44%", marginTop: "85px"  }}></span>
                <MenuItem component="fuel-injection" contentTitle="Fuel injection system" title="Maximum uptime" content={maximum_uptime_content} onClick={this.handleOnClick} head="tad-petrie-injection-systems" />
                <MenuItem component="fuel-injection" contentTitle="Fuel injection system" title="Low maintenance" content={low_maintenance_content} onClick={this.handleOnClick} head="ellie-injectors" />
                <MenuItem component="fuel-injection" contentTitle="Fuel injection system" title="High re-sale value" content={high_resale_value_content} onClick={this.handleOnClick} head="bertrand-injectors" />
            </ul>
        );
    }
}

export default MenuFuelInjection;
